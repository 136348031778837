<template>
  <div class="account-summary-vue">
    <Header>
      <template v-slot:title>
        <span v-if="whitelabel === 'Blackstone'"> My Trading Accounts </span>
        <span v-else>
          {{ $t("account-summary") }}
        </span>
      </template>
      <template v-slot:buttons>
        <span class="inline-flex rounded-md">
          <button
            v-if="
              whitelabel !== 'Blackstone' &&
              whitelabel !== 'OrbitInvest' &&
              whitelabel !== 'VCGMarkets'
            "
            class="relative inline-flex items-center pr-4 pl-1 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
            type="button"
            @click="showAddDemoAccountModal = true"
          >
            <svg
              class="h-5 w-0 mr-2 text-white group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
            <span class="hidden md:flex">
              {{ $t("add-a-new-demo-account") }}
            </span>
            <span class="md:hidden flex">
              <svg
                class="h-5 mr-2 text-gray-700 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
              {{ $t("demo") }}
            </span>
          </button>
        </span>
        <span
          v-if="
            get_profile.state !== 'Demo' &&
            whitelabel !== 'Blackstone' &&
            whitelabel !== 'OrbitInvest' &&
            whitelabel !== 'VCGMarkets'
          "
          class="inline-flex rounded-md shadow-sm ms-3"
        >
          <button
            class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
            type="button"
            @click="showAddAccountModal = true"
          >
            <svg
              class="h-5 mr-2 text-white group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
            <span class="hidden md:flex">
              {{ $t("create-a-new-trading-account") }}
            </span>
            <span class="md:hidden">
              {{ $t("new") }}
            </span>
          </button>
        </span>
        <span
          v-if="whitelabel === 'Blackstone'"
          class="inline-flex rounded-md shadow-sm ms-3"
        >
          <router-link
            class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
            to="/add-new-live-account"
            type="button"
          >
            <svg
              class="h-5 mr-2 text-white group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
            <span class="hidden md:flex">
              {{ $t("create-a-new-trading-account") }}
            </span>
            <span class="md:hidden">
              {{ $t("new") }}
            </span>
          </router-link>
        </span>
        <span
          v-if="whitelabel === 'Blackstone'"
          class="inline-flex rounded-md shadow-sm ms-3"
        >
          <router-link
            class="relative inline-flex items-center pr-4 pl-1 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
            to="/add-new-demo-account"
            type="button"
          >
            <svg
              class="h-5 mr-2 text-gray-700 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
            <span class="hidden md:flex">
              {{ $t("create-a-new-demo-account") }}
            </span>
            <span class="md:hidden">
              {{ $t("new") }}
            </span>
          </router-link>
        </span>
        <div
          class="flex gap-2 md:gap-4 flex-wrap justify-end"
          v-if="whitelabel === 'VCGMarkets'"
        >
          <LinkButton
            v-if="whitelabel === 'VCGMarkets' && get_profile.state === 'Demo'"
            to="/open-live-account"
            :button-text="$t('Complete registration')"
          />

          <LinkButton
            v-if="
              (get_profile.state === 'Limited' ||
                get_profile.state === 'Pending') &&
              whitelabel === 'VCGMarkets'
            "
            to="/upload-documents"
            :button-text="$t('Upload documents')"
          />
          <LinkButton
            v-if="whitelabel === 'VCGMarkets' && get_profile.state === 'Full'"
            to="/deposit-funds"
            :button-text="$t('Fund your account')"
          />
        </div>
      </template>
    </Header>
    <iframe
      v-if="whitelabel === 'Blackstone'"
      allowtransparency="true"
      border="0"
      height="100%"
      scrolling="yes"
      src="https://blackstonefutures.co.za/banner/"
      style="border: 0"
      width="100%"
    ></iframe>
    <h5
      v-if="whitelabel === 'Blackstone'"
      class="flex text-gray-700 text-sm mt-2 justify-center"
    >
      <svg
        class="w-6 h-6 mr-2"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </svg>
      MT4 servers: BlackstoneFutures-LiveBravo | BlackstoneFutures-DemoBravo
    </h5>
    <div
      v-if="whitelabel !== 'OrbitInvest'"
      class="sm:px-6 bg-white flex overflow-auto whitespace-nowrap"
    >
      <div v-for="(backend, index) in get_demo_backend_accounts" :key="index">
        <button
          v-if="
            get_account_data
              .filter((item) => item.backend.type === backend.backend.type)
              .filter((item) => item.backend.mode === 'Live').length !== 0 ||
            get_account_data
              .filter((item) => item.backend.type === backend.backend.type)
              .filter((item) => item.backend.mode === 'Demo').length !== 0
          "
          :class="
            selectedTab === backend.backend.type
              ? 'border-b-2 border-blue-900'
              : ''
          "
          class="px-4 py-3 mr-4 focus:outline-none border-b-2 border-transparent inline-block whitespace-normal"
          @click="selectedTab = backend.backend.type"
        >
          <img
            v-if="backend.backend.type === 'DXTrade'"
            :src="checkAsset(backend.backend.type)"
            alt=""
            class="h-6 w-auto"
          />
          <img
            v-else
            :src="checkAsset(backend.backend.type)"
            alt=""
            class="m-auto"
          />
        </button>
      </div>
    </div>
    <complete-id-identification
      v-if="
        $store.getters.get_profile.state !== 'Full' &&
        $store.getters.get_profile.state !== 'Demo' &&
        $store.getters.get_profile.state !== 'Limited' &&
        (whitelabel === 'ICMEU' ||
          whitelabel === 'ICMCapital' ||
          whitelabel === 'ICMMENA' ||
          whitelabel === 'GCCBrokers' ||
          whitelabel === 'TradiNext' ||
          whitelabel === 'ICMTrader' ||
          whitelabel === 'ICMAP' ||
          whitelabel === 'TD365' ||
          whitelabel === 'TDSouthAfrica' ||
          whitelabel === 'EagleGlobalMarkets' ||
          whitelabel === 'TradeCoreUK' ||
          whitelabel === 'OrbitInvest' ||
          whitelabel === 'OneTradingMarkets')
      "
      :title="
        $t('further-id-verification-required-to-progress-account-application')
      "
    >
    </complete-id-identification>
    <alert-message
      :show-title="whitelabel !== 'VCGMarkets'"
      v-if="this.alertMessage.message !== ''"
      :alertMessage="this.alertMessage.message"
      :link="this.alertMessage.link"
      :linkText="this.alertMessage.linkText"
    >
    </alert-message>
    <div
      v-if="
        $store.getters.get_profile.state === 'Full' &&
        whitelabel === 'OrbitInvest'
      "
      class="sm:p-6 mt-4"
    >
      <div class="p-2 text-gray-500 font-light flex summary-info">
        <span class="block">Trading Account</span>
        <div class="summary-info__data">
          Total investment
          <span class="font-medium">{{
            $store.getters.get_user_data.extra.total_investment
          }}</span>
        </div>
      </div>
      <div class="p-2 text-gray-500 font-light flex summary-info">
        <span class="block">Profit Account</span>
        <div class="summary-info__data">
          Cash Balance
          <span class="font-medium">{{
            $store.getters.get_user_data.extra.total_profits
          }}</span>
        </div>
      </div>
    </div>
    <div
      v-for="(backend, index) in get_demo_backend_accounts"
      v-if="
        backend.backend.type === selectedTab && whitelabel !== 'OrbitInvest'
      "
      :key="index"
      class="sm:p-6 mt-4"
    >
      <div class="p-2 text-gray-500 font-light flex">
        <span class="block"> {{ $t("live-accounts") }} </span>
        <span class="px-1 bg-white border rounded text-sm mx-1 text-blue-900">
          {{
            get_account_data
              .filter((item) => item.backend.type === backend.backend.type)
              .filter((item) => item.backend.mode === "Live").length
          }}
        </span>
      </div>
      <card
        v-for="(data, index) in get_account_data
          .filter((item) => item.backend.type === backend.backend.type)
          .filter((item) => item.backend.mode === 'Live')"
        :key="index + 'Live'"
        :data="data"
      />
      <div class="p-2 text-gray-500 font-light mt-16 flex">
        <span class="block"> {{ $t("demo-accounts") }} </span>
        <span class="px-1 bg-white border rounded text-sm mx-1 text-blue-900">
          {{
            get_account_data
              .filter((item) => item.backend.type === backend.backend.type)
              .filter((item) => item.backend.mode === "Demo").length
          }}
        </span>
      </div>
      <card
        v-for="(data, index) in get_account_data
          .filter((item) => item.backend.type === backend.backend.type)
          .filter((item) => item.backend.mode === 'Demo')"
        :key="index + 'Demo'"
        :data="data"
      />
    </div>
    <add-demo-account-modal
      v-if="showAddDemoAccountModal"
      @close="closeModal"
    />
    <add-live-account-modal v-if="showAddAccountModal" @close="closeModal" />
    <gcc-demo-popup
      v-if="showGccDemoPopup"
      @close="closeGccPopup"
      @continue="continueGccPopup"
    ></gcc-demo-popup>
    <icm-academy-modal v-if="showIcmAcademyModalComputed" @close="closeModal" />
    <icm-popup v-if="showICMPopup" />
    <icmcapital-unsuitable-modal
      v-if="$store.getters.get_icm_capital_suitable_modal"
    />

    <proof-of-id-modal
      v-if="$store.getters.get_icm_proof_modal === 'ID Proof'"
      :documents="['ID Proof']"
    ></proof-of-id-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MoneyFormat from "vue-money-format";
import AddDemoAccountModal from "@/views/trading-accounts/AddDemoAccountModal";
import AddLiveAccountModal from "@/views/trading-accounts/AddLiveAccountModal";
import Header from "@/components/Header";
import Card from "@/views/trading-accounts/Card";
import CompleteIdIdentification from "@/components/CompleteIdIdentification";
import GccDemoPopup from "@/views/trading-accounts/GccDemoPopup";
import IcmAcademyModal from "@/views/trading-accounts/IcmAcademyModal";
import IcmcapitalUnsuitableModal from "@/views/register/IcmcapitalUnsuitableModal";
import AlertMessage from "@/components/AlertMessage.vue";
import LinkButton from "@/components/LinkButton.vue";
import ButtonComponent from "@/components/Button.vue";
import IcmPopup from "@/views/trading-accounts/ICMPopup.vue";
import LimitedStateUpgradeModal from "@/views/LimitedStateUpgradeModal.vue";
import ProofOfIdModal from "@/views/ProofOfIdModal.vue";

export default {
  name: "AccountSummary",
  components: {
    ProofOfIdModal,
    LimitedStateUpgradeModal,
    IcmPopup,
    ButtonComponent,
    LinkButton,
    Card,
    AddDemoAccountModal,
    AddLiveAccountModal,
    Header,
    "money-format": MoneyFormat,
    CompleteIdIdentification,
    AlertMessage,
    GccDemoPopup,
    IcmAcademyModal,
    IcmcapitalUnsuitableModal,
  },
  data() {
    return {
      showAddDemoAccountModal: false,
      showAddAccountModal: false,
      showMenu: false,
      stage: "",
      whitelabel: process.env.VUE_APP_WHITELABEL,
      selectedTab: "",
      showGccDemoPopup: false,

      intervalId: null,
    };
  },
  computed: {
    ...mapGetters([
      "get_account_data",
      "get_profile",
      "get_user_data",
      "get_icm_academy_modal",
      "get_icm_capital_suitable_modal",
    ]),

    alertMessage() {
      let message = "";
      let link = "";
      let linkText = "";

      const status = this.$store.getters.get_profile.state;

      console.log("status", status);

      if (
        (this.whitelabel === "ICMVC" && status === "Pending") ||
        (this.whitelabel === "ICMMU" && status === "Pending")
      ) {
        // documents status
        // 1 - pending
        // 2 - approved
        // 3 - rejected
        // 4 - failed
        const documents = this.$store.getters.get_uploaded_documents?.results;
        console.log("documents", documents);
        const idProof = documents?.find(
          (document) => document.document_type === "ID Proof",
        );
        console.log("idProof", idProof);
        if (documents?.length === 0) {
          message = this.$t(
            "further-id-verification-required-to-progress-account-application",
          );
          link = "/upload-documents";
          linkText = "Click here to upload.";
        }

        if (idProof) {
          if (idProof?.status === 1) {
            message =
              "We're currently verifying your details. Please bear with us.\n" +
              "Thank you for your patience.";
          }

          if (idProof.status === 3 && status === "Pending") {
            message =
              "Further ID verification required to progress account application.";
            link = "/upload-documents";
            linkText = "Click here to upload.";
          }
        }
      }

      return {
        message,
        link,
        linkText,
      };
    },
    get_demo_backend_accounts() {
      let data = this.$store.getters.get_demo_backend_accounts;
      if (this.$store.getters.get_demo_backend_accounts.length === 0) {
        data = this.$store.getters.get_live_backend_accounts;
      }
      let accountData = this.$store.getters.get_account_data;
      if (accountData.length > 0) {
        let result = accountData.filter(function (o1) {
          // filter out (!) items in result2
          return !data.some(function (o2) {
            return o1.backend.type === o2.backend.type; // assumes unique id
          });
        });
        result = data.concat(result).reduce((unique, o) => {
          if (
            !unique.some(
              (obj) =>
                obj.backend.type === o.backend.type &&
                obj.backend.type === o.backend.type,
            )
          ) {
            unique.push(o);
          }
          return unique;
        }, []);

        // client wanted MT4 to be always as first tab and cTrader to be last tab
        // we check if there is a object with backend type of MT4
        // if it does, we check if its Index is not in the first position of bekend accounts
        // if its not, we reverse the order of array so he ends up in first place
        const mt4exist = result.some((object) => object.backend.type === "MT4");
        if (mt4exist) {
          if (
            result.findIndex((object) => object.backend.type === "MT4") !== 0
          ) {
            result.reverse();
          }
        }

        // this will loop through all trading backend types and check if it has active accounts
        // if it has, we can then set the selected tab to that one
        // this is places so we dont select a tab that we might not show, since we added a filter not to show tabs with no accounts
        for (const key in result) {
          if (
            accountData
              .filter((item) => item.backend.type === result[key].backend.type)
              .filter((item) => result[key].backend.mode === "Live").length !==
              0 ||
            accountData
              .filter((item) => item.backend.type === result[key].backend.type)
              .filter((item) => result[key].backend.mode === "Demo").length !==
              0
          ) {
            this.selectedTab = result[key].backend.type;
          }
        }

        // this function will sort tabs (backend types) based on the number of trading accounts that have that backend type
        // we loop through all backend types and then add all live and demo accounts with that bakend types, then compare with live + demo accounts from the other backend type from the array
        // this is the logic (p1, p2) => (p1.price < p2.price) ? 1 : (p1.price > p2.price) ? -1 : 0);
        // code taken from here https://www.scaler.com/topics/javascript-sort-an-array-of-objects/
        if (this.whitelabel === "TradeCoreUK") {
          let sortedProducts = result.sort((p1, p2) =>
            parseInt(
              accountData
                .filter((item) => item.backend.type === p1.backend.type)
                .filter((item) => p1.backend.mode === "Live").length,
            ) +
              parseInt(
                accountData
                  .filter((item) => item.backend.type === p1.backend.type)
                  .filter((item) => p1.backend.mode === "Demo").length,
              ) <
            parseInt(
              accountData
                .filter((item) => item.backend.type === p2.backend.type)
                .filter((item) => p2.backend.mode === "Live").length,
            ) +
              parseInt(
                accountData
                  .filter((item) => item.backend.type === p2.backend.type)
                  .filter((item) => p2.backend.mode === "Demo").length,
              )
              ? 1
              : parseInt(
                    accountData
                      .filter((item) => item.backend.type === p1.backend.type)
                      .filter((item) => p1.backend.mode === "Live").length,
                  ) +
                    parseInt(
                      accountData
                        .filter((item) => item.backend.type === p1.backend.type)
                        .filter((item) => p1.backend.mode === "Demo").length,
                    ) >
                  parseInt(
                    accountData
                      .filter((item) => item.backend.type === p2.backend.type)
                      .filter((item) => p2.backend.mode === "Live").length,
                  ) +
                    parseInt(
                      accountData
                        .filter((item) => item.backend.type === p2.backend.type)
                        .filter((item) => p2.backend.mode === "Demo").length,
                    )
                ? -1
                : 0,
          );
        }

        // this.selectedTab = result[0].backend.type
        return result;
      }
    },
    showICMPopup() {
      return this.$store.getters.get_icm_popup;
    },
    showIcmAcademyModalComputed() {
      if (this.$store.getters.get_profile.address !== undefined) {
        const userCountry =
          this.$store.getters.get_profile.address?.addr_country; // get user country
        const isIcmAcademyModal = this.$store.getters.get_icm_academy_modal; // get a boolean for ICM Academy modal
        const whitelabel = this.whitelabel;

        // Check the conditions
        if (
          isIcmAcademyModal &&
          (whitelabel === "ICMVC" || whitelabel === "ICMMU") &&
          !["Malaysia", "Vietnam", "Thailand", "Indonesia"].includes(
            userCountry,
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },

  created() {
    this.stage = process.env.VUE_APP_API_URL.includes("dev.")
      ? "dev"
      : process.env.VUE_APP_API_URL.includes("stage.")
        ? "stage"
        : "production";

    if (this.whitelabel === "GCCBrokers") {
      this.showGccDemoPopup = this.$store.getters.get_gcc_demo_popup;
    }

    if (this.whitelabel === "GCCBrokers") {
      this.showGccDemoPopup = this.$store.getters.get_gcc_demo_popup;
    }

    if (this.whitelabel === "ICMVC" || this.whitelabel === "ICMMU") {
      this.fetchUserDocs();
      // Initialize the interval and save intervalId to the component instance
      this.intervalId = setInterval(() => this.fetchUserDocs(), 10000); // Fetch user data every 10 seconds
    }
  },

  watch: {
    "this.$store.getters.get_profile": {
      handler(newProfile, oldProfile) {
        // This function will be called when `get_profile` changes
        this.profile = newProfile;
      },
    },
  },

  methods: {
    fetchUserDocs() {
      const documents = this.$store.getters.get_uploaded_documents?.results;
      const status = this.$store.getters.get_profile.state;

      const idProof = documents?.find(
        (document) => document.document_type === "ID Proof",
      );

      const needToFetch = idProof?.status !== 2 && status !== "Limited";
      if (needToFetch) {
        this.$store.dispatch("set_uploaded_documents");
      } else {
        clearInterval(this.intervalId);
      }
    },
    closeModal() {
      this.showAddDemoAccountModal = false;
      this.showAddAccountModal = false;
      this.showIcmAcademyModal = false;
    },

    checkAsset(name) {
      try {
        return require(`../../assets/${name}-tab-icon.png`);
      } catch (e) {
        return require(`../../assets/logo-placeholder.png`);
      }
    },
    closeGccPopup() {
      this.showGccDemoPopup = false;
      this.$store.commit("SET_GCC_DEMO_POPUP", false);
    },
    continueGccPopup() {
      this.showGccDemoPopup = false;
      this.$store.commit("SET_GCC_DEMO_POPUP", false);
      this.$router.push("/open-live-account");
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
